import { useBreakpoint } from '@pretto/bricks/assets/utility'
import { Wrapper } from '@pretto/bricks/components/layout/Wrapper/styles'

import PropTypes from 'prop-types'

import * as S from './styles'

const ASTERIKS =
  '* Ces taux sont des taux moyens. Vous devez faire une simulation pour connaître votre taux personnalisé.'

const RatesBank = ({ title, bankData, rates }) => {
  const { isMobile } = useBreakpoint()
  const hasRates = rates.length > 0

  return (
    <Wrapper>
      <S.RatesBank $hasRates={hasRates}>
        <S.Title>{title}</S.Title>
        <S.Headline>{bankData.headline}</S.Headline>
        {bankData.logos && (
          <S.LogoContainer>
            <S.Logo path={isMobile ? bankData.logos.regular : bankData.logos.light} />
          </S.LogoContainer>
        )}
        <S.Picture />
      </S.RatesBank>

      {hasRates && (
        <S.RatesSection>
          <S.RatesContainer>
            {rates.map(rate => (
              <S.Rate {...rate} key={`${rate.duration}-${rate.value}`} />
            ))}
          </S.RatesContainer>
          <S.Asteriks>{ASTERIKS}</S.Asteriks>
        </S.RatesSection>
      )}
    </Wrapper>
  )
}

RatesBank.propTypes = {
  /** Data of the bank. */
  bankData: PropTypes.object,
  /** Main title of component. */
  title: PropTypes.string.isRequired,
  /** Rates data */
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
      href: PropTypes.string,
      value: PropTypes.number,
    })
  ),
}

export default RatesBank
