import * as helpers from '@pretto/bricks/components/helpers'
import Image from '@pretto/bricks/components/images/Illustration'
import IllustrationDeprecated from '@pretto/bricks/components/images/IllustrationDeprecated'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import * as typo from '@pretto/bricks/core/typography'

import loadable from '@loadable/component'
import styled, { css } from 'styled-components'

const CardBlack = loadable(() => import('@pretto/zen/seo/cards/CardBlack/CardBlack'), {
  resolveComponent: components => components.CardBlack,
})

export const RatesBank = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.neutral1};
  border-radius: ${({ $hasRates }) => ($hasRates ? `${g(1)} ${g(1)} 0px 0px` : `${g(1)}`)};
  position: relative;
  z-index: 0;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
    background-color: ${({ theme }) => theme.colors.primary1};
    color: ${({ theme }) => theme.colors.white};
    padding: ${g(6)} 0;
    display: grid;
    grid-template-columns: 0 8fr 0 4fr 0;
    grid-template-rows: repeat(3, auto);
    grid-gap: ${g(3)};

    ${helpers.shape(
      css`
        background-position: ${g(-74)} ${g(-54)};
        background-size: ${g(102)} auto;
        transform: translateX(100%) rotateZ(90deg);
        transform-origin: 0 0;
      `,
      { variant: 'primary2' }
    )}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: 1fr 6fr 1fr 3fr 1fr;
  }
`
export const Title = styled(BaseTypo).attrs({ forwardedAs: 'h1', typo: 'heading48' })`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column-start: 2;
    margin-bottom: 0;
  }
`
export const Headline = styled.div`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column-start: 2;
    margin-bottom: 0;
  }
`
export const LogoContainer = styled.div`
  display: flex;
  margin-bottom: ${g(2)};
  justify-content: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column-start: 2;
    margin-bottom: 0;
    justify-content: left;
  }
`
export const Logo = styled(Image).attrs({ options: { crop: 'fill', height: ng(7) } })`
  display: block;
`
export const Picture = styled(IllustrationDeprecated).attrs({ name: 'expertWithPercent' })`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    grid-row: 1 / -1;
    grid-column-start: 4;
    align-self: center;
  }
`
export const RatesSection = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${g(4)};
  padding: ${g(2, 4)} 0px ${g(2, 4)} 0px;
  border-radius: 0px 0px ${g(1)} ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)} ${g(4)} ${g(4)} ${g(4)};
    background-color: ${({ theme }) => theme.colors.neutral4};
  }
`
export const RatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  width: 100%;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
    flex-direction: row;
  }
`
export const Rate = styled(CardBlack)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral3};

  @media screen and (min-width: ${breakpoints.tablet}) {
    border: none;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    max-width: 222px;
  }
`
export const Asteriks = styled.div`
  ${typo.bodyBook16};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${g(2)};
  color: ${({ theme }) => theme.colors.neutral2};
  text-align: center;
`
