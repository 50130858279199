import RatesBankPage from '@pretto/bricks/website/bank/pages/RatesBank'

import bankInformations from '@pretto/config/banks.json'
import { TauxImmobilierBanque as TauxImmobilierBanqueView } from '@pretto/website/src/templates/tauxImmobilierBanque/views/TauxImmobilierBanque/TauxImmobilierBanque'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const MONTHS_IN_YEAR = 12

export const TauxImmobilierBanque = ({ data, rates, ...props }) => {
  const ratesToDisplay = rates.data.marketRatesLatest.map(({ averageRate, duration }) => ({
    duration: duration / MONTHS_IN_YEAR,
    href: 'https://app.pretto.fr/',
    value: parseFloat(averageRate.toFixed(2)),
  }))

  const bankInformation = bankInformations.filter(bank => bank.value === data.template.ratesBank.image)
  const bankData = {
    headline: data.template.ratesBank.headline,
    logos: bankInformation.length > 0 ? bankInformation[0].logo : null,
  }

  const headerComponent = useMemo(
    () => <RatesBankPage bankData={bankData} rates={ratesToDisplay} title={data.title} />,
    []
  )

  return <TauxImmobilierBanqueView {...props} headerComponent={headerComponent} />
}

TauxImmobilierBanque.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.shape({
      ratesBank: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        image: PropTypes.string,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  rates: PropTypes.object,
}

export const fragment = graphql`
  fragment TauxImmobilierBanqueTemplate on WpTemplate_TauxImmobilierParBanque {
    ratesBank {
      headline
      image
    }
  }
`
